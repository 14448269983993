<template>
    <div>
        正在进入系统...
    </div>
</template>

<script>
export default {
    setup () {
        return {}
    },
    created () {
        if(this.$route.query.code){
            this.$http.get(`admin/v1/login/oauth/sign?code=${this.$route.query.code}&state=${this.$route.query.state}`).then(res=>{
                if(res.code==0){
                    //存储token、跳转
                    sessionStorage.setItem('token',res.data.token);
                    this.$router.push({ name: "dashboard" });
                }
            })
        }
    },
}
</script>

<style scoped>

</style>